import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { Constants } from "../../../../constants/constants";
import { GcpAccountsService } from "../gcp-accounts.service";
import { TitleService } from "../../../../services/title.service";
import { take } from "rxjs/operators";
import { GcpAccount } from "src/app/models/shared";

@Component({
    selector: "app-gcp-account-form",
    templateUrl: "./gcp-account-form.component.html"
})
export class GcpAccountFormComponent implements OnInit, OnDestroy {
    gcpAccount: GcpAccount;
    gcpAccountNames: string[];
    accountId: number;
    action: string;

    submitted = false;
    minLength = 2;
    saving = false;
    isEdit: boolean;
    loading = true;
    authJson: string;
    jsonError = false;
    constants = Constants;

    private gcpAccountsSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ts: GcpAccountsService,
        private titleService: TitleService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.accountId = parseInt(params.get("id"), 10);
            this.action = params.get("action");
            if (this.accountId != null) {
                this.ts.refreshGcpAccounts();

                this.ts.gcpAccounts.pipe(take(1)).subscribe(azureAccounts => {
                    this.gcpAccount = Object.assign(
                        {},
                        azureAccounts.find(t => t.id === this.accountId)
                    );
                    this.prepForm();
                    this.loading = false;
                });
            } else {
                this.prepForm();
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.action) {
            if (this.action === "edit") this.isEdit = true;
        }

        if (!this.gcpAccount && !this.isEdit) {
            this.gcpAccount = new GcpAccount();
            this.gcpAccount.name = "";
        }

        // Set Title
        this.titleService.setTitle("GCP_ACCOUNT", this.action, this.gcpAccount);
    }

    ngOnInit() {
        this.ts.refreshGcpAccounts();

        this.gcpAccountsSubscription = this.ts.gcpAccounts.subscribe((gcpAccounts: GcpAccount[]) => {
            if (this.isEdit) this.gcpAccountNames = gcpAccounts.filter(t => t.id !== this.accountId).map(t => t.name);
            else this.gcpAccountNames = gcpAccounts.map(t => t.name);
        });
    }

    ngOnDestroy() {
        if (this.gcpAccountsSubscription) this.gcpAccountsSubscription.unsubscribe();
    }

    onFileChange(event) {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            reader.readAsText(file);

            reader.onload = () => {
                this.authJson = reader.result as string;
                this.jsonError = false;
            };
        }
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.gcpAccount.name
        };

        try {
            Object.assign(model, JSON.parse(this.authJson));
        } catch (e) {
            this.jsonError = true;
            this.saving = false;
            return;
        }

        if (this.isEdit) {
            const result = await this.ts.updateGcpAccount(this.gcpAccount, model);
            if (result !== false) {
                this.saving = false;
                this.router.navigate([Constants.urls.configuration.gcp]);
            } else this.saving = false;
        } else {
            const result = await this.ts.addGcpAccount(model);
            if (result !== false) {
                this.saving = false;
                this.router.navigate([Constants.urls.configuration.gcp]);
            } else this.saving = false;
        }
    }

    cancel() {
        this.router.navigate([Constants.urls.configuration.gcp]);
    }
}
